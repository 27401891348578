import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

import ArrowTile from "components/Icons/arrow"

const NewsTileRow = ({ item }) => {
  return (
    <Link to={`/aktualnosci${item.uri}`} className="news-tile-row">
      <div className="row">
        <div className="col-md-6">
          <div className="news-tile-row__wrapper">
            <div
              className="news-tile-row__image"
              style={{
                backgroundImage: `url('${item?.featuredImage?.node?.localFile?.publicURL}')`,
              }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="news-tile-row__inner">
            <div>
              <p className="news-tile-row__date">
                {item?.date.split("T")[0].split("-").reverse().join("-")}
              </p>
              <h3>{item?.title}</h3>
              <div
                className="news-tile-row__excerpt"
                dangerouslySetInnerHTML={{ __html: item?.excerpt }}
              />
            </div>
            <ArrowTile />
          </div>
        </div>
      </div>
    </Link>
  )
}

export default NewsTileRow
