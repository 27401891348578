import "./styles.scss"

import React from "react"

const PostContent = ({ data }) => {
  return (
    <section className="post-content">
      <div className="container-fluid">
        <div className="post-content__wrapper">
          <div className="row">
            <div className="col-md-2">
              <p className="post-content__date">
                {data?.data?.date.split("T")[0].split("-").reverse().join(".")}
              </p>
            </div>
            <div className="col-md-9">
              <h2>{data.title}</h2>
              {data?.data?.excerpt && (
                <div
                  className="post-content__excerpt"
                  dangerouslySetInnerHTML={{ __html: data?.data?.excerpt }}
                />
              )}
              {data?.data?.featuredImage?.node?.localFile?.publicURL && (
                <div
                  className="post-content__image"
                  style={{
                    backgroundImage: `url('${data?.data?.featuredImage?.node?.localFile?.publicURL}')`,
                  }}
                />
              )}
              <div
                className="post-content__content"
                dangerouslySetInnerHTML={{ __html: data?.data?.content }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PostContent
