import React from "react"

import Button from "components/Button"
import NewsTile from "components/NewsTile"

import { usePosts } from "context/posts"

const PostOther = ({ allWpPost, id }) => {
  const { allPosts } = usePosts()

  return (
    allPosts?.length > 0 && (
      <section className="home-news">
        <div className="container-fluid">
          <div className="home-news__row">
            <h2>Aktualności</h2>
            <Button to="/aktualnosci/" className="button--bordered">
              zobacz wszystkie
            </Button>
          </div>
          <div className="row">
            {allPosts
              ?.filter(item => item.id !== id)
              ?.slice(0, 3)
              .map((item, index) => (
                <div className="col-md-4" key={index}>
                  <NewsTile item={item} className="news-tile--smaller" />
                </div>
              ))}
          </div>
        </div>
      </section>
    )
  )
}

export default PostOther
