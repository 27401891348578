import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

import ArrowTile from "components/Icons/arrow"

const NewsTile = ({ item, className }) => {
  return (
    <Link
      to={`/aktualnosci${item.uri}`}
      className={`news-tile${className ? ` ${className}` : ""}`}
    >
      <div className="news-tile__wrapper">
        <div
          className="news-tile__image"
          style={{
            backgroundImage: `url('${item?.featuredImage?.node?.localFile?.publicURL}')`,
          }}
        />
      </div>
      <p className="news-tile__date">
        {item?.date.split("T")[0].split("-").reverse().join("-")}
      </p>
      <h3>{item?.title}</h3>
      <ArrowTile />
    </Link>
  )
}

export default NewsTile
